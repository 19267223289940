import React from 'react'
import Reveal from 'react-awesome-reveal'
// eslint-disable-next-line import/no-extraneous-dependencies
import { keyframes } from '@emotion/react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function CustomFadeSlide({
  children,
  delay,
  direction,
  className,
  ...rest
}) {
  const classes = useStyles()
  const startingX = direction === 'left' ? '-100px' : '100px'
  const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(${startingX}, 0px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

  return (
    <Reveal
      className={`${classes.container} ${className}`}
      triggerOnce
      delay={delay}
      keyframes={customAnimation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Reveal>
  )
}

CustomFadeSlide.propTypes = {
  delay: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

CustomFadeSlide.defaultProps = {
  direction: 'left',
  delay: 0,
  className: '',
}
