import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  typist: {
    opacity: 1,
    color: '#2FA43B',
    transition: (props) =>
      `opacity ${String(props.animationDuration / 1000)}s ease-out`,
  },
  fade: {
    opacity: 0,
  },
}))

/**
 * Component to display a series of words in sequence with a fade animation between them
 *
 * Total time between words showing is calculated as:
 * transitionInterval + animationDuration * 2 + initialDelay
 */
export default function FadingText({
  phrases,
  initialDelay,
  animationDuration,
  fadeDelay,
  transitionInterval,
  onReachArrayEnd,
}) {
  const [phraseIndex, setPhraseIndex] = useState(0)
  const [fadedOut, setFadedOut] = useState(false)
  const [isInitial, setIsInitial] = useState(true)

  const classes = useStyles({ animationDuration })

  /**
   * Handles the initiating the fade out for word transitions
   */
  useEffect(() => {
    const id = setTimeout(() => {
      setFadedOut(true)
      setIsInitial(false)
    }, transitionInterval + animationDuration * 2 + initialDelay * Number(isInitial) + fadeDelay)

    return () => {
      clearTimeout(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fadedOut, animationDuration, fadeDelay, transitionInterval, initialDelay])

  /**
   * Handles the changing of phrases and fading back in
   */
  useEffect(() => {
    // Change word only when completely faded out
    const id = setTimeout(() => {
      if (fadedOut === true) {
        setPhraseIndex((currPhraseIndex) => {
          if (currPhraseIndex === phrases.length - 1) {
            onReachArrayEnd()
            return 0
          }

          return currPhraseIndex + 1
        })
        setFadedOut(false)
      }
    }, fadeDelay + animationDuration)

    return () => {
      clearTimeout(id)
    }
  }, [fadedOut, phrases, animationDuration, fadeDelay, onReachArrayEnd])

  return (
    <span className={`${classes.typist} ${fadedOut ? classes.fade : ''}`}>
      {phrases[phraseIndex]}
    </span>
  )
}

FadingText.propTypes = {
  /**
   * Arry of words/phrases to transition between
   */
  phrases: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Time in ms for a single fade transition (in or out) to complete
   */
  animationDuration: PropTypes.number,

  /**
   * Duration in ms for the phrase to remain hidden before beginning to fade back in
   */
  fadeDelay: PropTypes.number,

  /**
   * Duration in ms between initiating word transitions
   */
  transitionInterval: PropTypes.number,

  /**
   * Additional time in ms to delay before beginning the first word transition
   */
  initialDelay: PropTypes.number,

  /**
   * Callback fired when the entire array has been iterated through
   */
  onReachArrayEnd: PropTypes.func.isRequired,
}

FadingText.defaultProps = {
  animationDuration: 400,
  fadeDelay: 100,
  transitionInterval: 1500,
  initialDelay: 1000,
}
