import React from 'react'
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { Fade } from 'react-awesome-reveal'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../../components/Divider'

import SecurityIcon from '../../assets/Individuals/Icons/empower_security.svg'
import PrivacyIcon from '../../assets/Individuals/Icons/respect_privacy.svg'
import UsabilityIcon from '../../assets/Individuals/Icons/improve_usability.svg'
// import diamondBG from '../../assets/Individuals/Images/diamond_bg.png'

import CTABox from '../../components/CTABox'
import routes from '../../routes'
import CTALink from '../../components/CTALink'
import CustomFadeSlide from '../../components/RevealAnim/CustomFadeSlide'
import FadingText from '../../components/FadingText'
import ContactUs from '../../components/ContactUs'

const HeroImage = '../../assets/Individuals/Images/header_image2.png'
const ImmeAppScreenShot = '../../assets/Individuals/Images/imme_app.png'
const underTheHoodLeft = '../../assets/Individuals/Images/underthehood_left.png'
const underTheHoodRight =
  '../../assets/Individuals/Images/underthehood_right.png'
const identityGrid = '../../assets/Individuals/Images/oneperson_oneidentity.png'
const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    textAlign: 'center',
    marginBottom: 15,
    maxWidth: 280,
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 525,
    },
  },
  sectionDescription: {
    textAlign: 'center',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },
  ctaSection: {
    paddingTop: 64,
    height: 218,
    marginBottom: 165,

    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      margin: 'auto',

      height: 172,
      paddingTop: 59,
    },
  },
  /**
   * || Hero Section
   * ======================================= */
  hero: {
    // backgroundColor: '#F0F4F8',
    height: 450,
    position: 'relative',
    top: -35, // Account for the header bar height
    paddingTop: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('tablet')]: {
      top: -78,
      justifyContent: 'flex-start',
      marginBottom: 'calc(20vw - 1vw)',
    },

    [theme.breakpoints.up('desktop')]: {
      marginBottom: -130,
      top: -48,
      justifyContent: 'flex-start',
      height: 650,
    },
    [theme.breakpoints.up('desktopXL')]: {
      // justifyContent: 'space-evenly',
    },
  },
  heroImage: {
    display: 'none !important',
    [theme.breakpoints.up('tablet')]: {
      display: 'block !important',
      width: 'calc(100% - 1vw)',
      maxWidth: 550,
      height: '100%',
      position: 'relative',
      // bottom: -100,
      marginRight: 30,
      marginTop: 'calc(100% - 25vw)',
    },

    [theme.breakpoints.up('desktop')]: {
      display: 'block !important',
      maxWidth: 550,
      height: 'auto',
      position: 'relative',
      top: 80,
      marginTop: 0,
    },
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 620,
      height: 'auto',
      top: 90,
      marginLeft: 70,
    },
  },

  heroContent: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    marginTop: '160px',
    [theme.breakpoints.up(600)]: {
      marginTop: 240,
      alignItems: 'flex-start',
      marginLeft: 25,
      marginRight: 25,
      width: 400,
    },
    [theme.breakpoints.up(865)]: {
      marginTop: 200,
      width: 400,
    },
    [theme.breakpoints.up(976)]: {
      alignItems: 'flex-start',
      marginLeft: 25,
      marginRight: 25,
      width: 412,
      marginTop: 320,
      // marginTop: '30px',
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',
      marginLeft: 50,
      marginRight: 50,
      width: 520,
      marginTop: 180,
      // marginTop: '30px',
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: 600,
    },
  },
  heroTitle: {
    color: 'black',
    whiteSpace: 'pre-line',
    fontSize: '32px',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: '55px',
    },
  },
  heroDescription: {
    textAlign: 'left',
    width: '86.67vw',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    margin: '24px 0px',
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
      padding: 'unset',
      maxWidth: 450,
      fontWeight: 400,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: '18px',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
    },
  },
  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      fontSize: '14px',
      fontWeight: 500,
      padding: 0,
      marginRight: 16,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '136px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      padding: 0,
      marginRight: 10,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '160px',
    },
  },
  typist: {
    color: '#2FA43B',
  },

  /**
   * ||Introduction to Imme Section
   * ======================================= */
  immeIntroSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',

    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    paddingBottom: 30,
    marginTop: 80,

    [theme.breakpoints.up('tablet')]: {
      marginTop: 0,
      flexDirection: 'row',
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 150,
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 200,
    },
  },
  introInformation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
    },
  },
  introTitle: {
    textAlign: 'center',
    marginBottom: 15,
    maxWidth: 280,

    [theme.breakpoints.up('tablet')]: {
      textAlign: 'left',
      fontSize: 'calc(9px + 0.7vw)',
      maxWidth: '100%',
    },

    [theme.breakpoints.up('700')]: {
      fontSize: 'calc(10px + 0.7vw)',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 'calc(15px + 0.7vw)',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: '25px',
    },
  },
  introBody: {
    textAlign: 'center',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    [theme.breakpoints.up('tablet')]: {
      textAlign: 'left',
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },
  imageWrapper: {
    // zIndex: 1,
    // position: 'absolute',
    // top: '280px',
    margin: 60,

    [theme.breakpoints.up('tablet')]: {
      margin: 50,
    },
    [theme.breakpoints.up('desktop')]: {
      margin: 120,
    },
  },
  immeAppScreenshot: {
    background: '#F4F4F4',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
    borderRadius: 20,
  },

  /**
   * ||Imme Features Section
   * ======================================= */
  immeFeaturesSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    paddingBottom: 60,

    // backgroundColor: '#F0F4F8',
    background: 'rgba(25, 49, 62, 1)',
  },

  immeFeatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginTop: 25,

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  immeFeature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: 360,
    margin: '50px auto 40px auto',

    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      margin: '50px 25px 40px 25px',
    },
  },

  /**
   * || Imme Under The Hood Section
   * ======================================= */
  hoodSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    paddingTop: 120,
    paddingBottom: 80,

    // backgroundImage: `url(${diamondBG})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',

    [theme.breakpoints.up('desktop')]: {
      paddingTop: 200,
      paddingBottom: 120,
    },
  },
  hoodCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    padding: '60px 15px',
    maxWidth: '90%',

    background: '#FFFFFF',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.up('desktop')]: {
      maxWidth: '80%',
    },
  },
  hoodCardImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  hoodCardImage: {
    width: '75%',
    height: 'auto',

    [theme.breakpoints.up('desktop')]: {
      width: '100%',
    },
  },

  /**
   * ||One Person, One Identity Section
   * ======================================= */
  identitySection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    marginTop: 60,

    [theme.breakpoints.up('desktopXL')]: {
      marginBottom: 150,
      marginTop: 90,
    },
  },
  identityImage: {
    width: '80%',
    height: 'auto',
    marginTop: 15,
    [theme.breakpoints.up('desktop')]: {
      width: '60%',
    },
  },

  // || span bold ||
  // ========================
  bold: {
    fontWeight: 900,
    color: 'black',
  },
  ctaBox: {
    marginTop: 100,
    marginBottom: 50,
    [theme.breakpoints.up('tablet')]: {
      marginBottom: 120,
      boxShadow: '0px 20px 50px rgba(18, 17, 39, 0.08)',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: 190,
    },
  },
}))

const phrases = [
  'Customers',
  'Gamers',
  'Users',
  'Patients',
  'Students',
  'Partners',
  'Traders',
  'Family',
  'Employees',
  'Community',
  'Stakeholders',
  'Creators',
  'Teammates',
  'Borrowers',
  'Clients',
  'Buyers',
  'Applicants',
  'Associates',
  'Candidates',
  'People',
  'Viewers',
  'Readers',
  'Consumers',
  'Donors',
  'Supporters',
]

/**
 * Randomly shuffles an array in-place
 * @param {array} array the array to be shuffled
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    // eslint-disable-next-line no-param-reassign
    array[i] = array[j]
    // eslint-disable-next-line no-param-reassign
    array[j] = temp
  }
}

export default function Individuals() {
  const classes = useStyles()

  const theme = useTheme()
  const isDesktopUp = useMediaQuery(theme.breakpoints.up('desktop'))
  const isDesktopXLUp = useMediaQuery(theme.breakpoints.up('desktopXL'))

  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <Fade triggerOnce delay={0}>
            {/* <Divider style={{ marginBottom: 12 }} /> */}
            {isDesktopUp ? (
              <>
                <Typography variant='h1' className={classes.heroTitle}>
                  It Starts With {!isDesktopXLUp ? '\n' : ''}{' '}
                </Typography>
                <Typography variant='h1' className={classes.heroTitle}>
                  Your{' '}
                  <FadingText
                    phrases={phrases}
                    onReachArrayEnd={() => shuffleArray(phrases)}
                  />
                </Typography>
              </>
            ) : (
              <>
                <Typography variant='h1' className={classes.heroTitle}>
                  It Starts With Your
                </Typography>
                <Typography variant='h1' className={classes.heroTitle}>
                  <FadingText
                    phrases={phrases}
                    onReachArrayEnd={() => shuffleArray(phrases)}
                  />
                </Typography>
              </>
            )}

            <Typography variant='body1' className={classes.heroDescription}>
              Modern business is all about connections. From the practical issue
              of building pathways to transact with customers, to the more
              abstract questions about forging emotional connections, your
              company&apos;s success depends on your ability to connect to your
              market.
            </Typography>
            <div className={classes.buttonContainer}>
              <CTALink
                className={classes.signUpButton}
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
              >
                Get Started
              </CTALink>

              <CTALink
                className={classes.signUpButton}
                href={routes.CONTACT}
                variant='outline'
              >
                Talk To Sales
              </CTALink>
            </div>
          </Fade>
        </div>

        <CustomFadeSlide delay={300} direction='right'>
          <StaticImage
            src={HeroImage}
            alt='Imme app features'
            className={classes.heroImage}
            loading='eager'
            placeholder='tracedSVG'
            quality={100}
          />
        </CustomFadeSlide>
      </section>

      <section className={classes.immeIntroSection}>
        <div className={classes.imageWrapper}>
          <Fade
            triggerOnce
            delay={400}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StaticImage
              className={classes.immeAppScreenshot}
              src={ImmeAppScreenShot}
              alt='Imme email registration screenshot'
              placeholder='tracedSVG'
              transformOptions={{ fit: 'inside' }}
              quality={100}
            />
          </Fade>
        </div>
        <div className={classes.introInformation}>
          <Fade triggerOnce delay={350}>
            <Divider style={{ marginBottom: 12 }} />
            <Typography variant='h2' className={classes.introTitle}>
              Putting Privacy in the Palm of Your Hand
            </Typography>
            <Typography variant='body1' className={classes.introBody}>
              <span className={`${classes.bold}`}>imme®</span> is a
              revolutionary Personal Identity Management System that connects
              new and existing users to your business. imme® offers end users
              enhanced privacy, reinforced security, accelerated onboarding, and
              effortless authentication, removing major barriers between your
              business and your customers.
            </Typography>
          </Fade>
          <Fade triggerOnce>
            <Divider style={{ marginTop: 70, marginBottom: 12 }} />
            <Typography variant='h2' className={classes.introTitle}>
              More Than a Log-in Tool
            </Typography>
            <Typography variant='body1' className={classes.introBody}>
              imme® is about giving users control of their digital lives.
              It&apos;s a lifestyle brand that promises to do things differently
              by offering creative solutions to problems that no one else is
              adequately addressing.
            </Typography>
          </Fade>
        </div>
      </section>

      <section className={classes.immeFeaturesSection}>
        <div className={classes.immeFeatureContainer}>
          <Fade triggerOnce delay={0} cascade duration={600}>
            <div className={classes.immeFeature}>
              <SecurityIcon color='white' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8, color: 'white' }}
              >
                Empowering Security
              </Typography>
              <Typography variant='body2' style={{ color: 'white' }}>
                Giving up personally-identifiable information to anyone
                increases the risk of becoming a victim of cybercrime. imme®
                lets users stay in sole control of their personal information.
              </Typography>
            </div>
            <div className={classes.immeFeature}>
              <PrivacyIcon color='white' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8, color: 'white' }}
              >
                Respecting Privacy
              </Typography>
              <Typography variant='body2' style={{ color: 'white' }}>
                Everyone has the right to privacy. imme® preserves this right by
                enabling verification, screening, and authentication without
                exchanging private personal information.
              </Typography>
            </div>
            <div className={classes.immeFeature}>
              <UsabilityIcon color='white' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8, color: 'white' }}
              >
                Improving Usability
              </Typography>
              <Typography variant='body2' style={{ color: 'white' }}>
                Legacy connectivity platforms create user experiences filled
                with friction. imme® transforms how digital access happens
                through our proprietary AI software and Zero-Knowledge
                protocols.
              </Typography>
            </div>
          </Fade>
        </div>
        <div className={classes.buttonContainer}>
          <CTALink className={classes.signUpButton} href='https://imme.io/'>
            Visit Imme
          </CTALink>
        </div>
      </section>

      <section className={classes.hoodSection}>
        <Fade
          triggerOnce
          delay={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={classes.hoodCard}>
            <Fade triggerOnce delay={200}>
              <Divider style={{ marginBottom: 12 }} />
              <Typography variant='h2' className={classes.sectionTitle}>
                Under the Hood
              </Typography>
              <Typography
                variant='body1'
                className={classes.sectionDescription}
              >
                Through imme®, your business can leverage our industry-leading
                technology to painlessly enhance online security, enable better
                user experience, and stay compliant with next-gen requirements.
              </Typography>
            </Fade>
            <div className={classes.hoodCardImagesContainer}>
              <Fade
                triggerOnce
                delay={500}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StaticImage
                  src={underTheHoodLeft}
                  alt='Imme feature technologies matrix 1'
                  className={classes.hoodCardImage}
                  placeholder='tracedSVG'
                  quality={100}
                />
              </Fade>
              <Fade
                triggerOnce
                delay={500}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StaticImage
                  src={underTheHoodRight}
                  alt='Imme feature technologies matrix 2'
                  className={classes.hoodCardImage}
                  placeholder='tracedSVG'
                  quality={100}
                />
              </Fade>
            </div>
          </div>
        </Fade>
      </section>

      <section className={classes.identitySection}>
        <Fade
          triggerOnce
          delay={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Divider style={{ marginBottom: 12 }} />
          <Typography variant='h2' className={classes.sectionTitle}>
            One Person, One Identity
          </Typography>
          <Typography variant='body1' className={classes.sectionDescription}>
            Your customers already have countless online accounts. imme®
            consolidates all of these into one simple, reusable, lifelong
            self-sovereign ID our users can use to access any of CycurID&apos;s
            partner services.
          </Typography>
          <br />
          <StaticImage
            className={classes.identityImage}
            src={identityGrid}
            alt='Imme identity management system'
            quality={100}
          />
        </Fade>
      </section>

      <Fade triggerOnce delay={0}>
        <CTABox
          title='Empower your users to connect with you in ways that will keep them safe, secure, and in the fast lane.'
          description='Visit the imme® website to learn more about how we’re making your user’s lives better, or contact us to integrate CycurID into your business and get the power of imme® working for you.'
          className={classes.ctaBox}
          buttons='Visit Imme'
        />
        {/* <div className={classes.buttonContainer}>
          <CTALink
            className={classes.signUpButton}
            variant={false}
            href={'https://imme.io/'}
          >
            Visit Imme
          </CTALink>
        </div> */}
      </Fade>

      <Fade triggerOnce fraction={0.3}>
        <ContactUs />
      </Fade>
    </main>
  )
}
